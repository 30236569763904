import { Button } from "src/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "src/components/ui/card";
import { cn } from "src/lib/utils";

export const ButtonComp = ({ children, className }: any) => (
  <Button
    className={cn(
      `rounded-full font-stolzl px-8  py-6 bg-[#D000A2]`,
      className
    )}
  >
    {children}
  </Button>
);

function HeroPage() {
  return (
    <>
      <div className="grid grid-cols-2 max-w-7xl mx-auto mt-20">
        <div className=" p-6  space-y-8 my-auto">
          <h1 className="font-stolzl text-5xl">Cloudzenia</h1>
          <h2 className="text-5xl font-extrabold font-sans ">Reward Program</h2>
          <p className="font-stolzl text-sm">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text.
          </p>
          <ButtonComp>{"Enroll as a partner"}</ButtonComp>
        </div>

        <div>
          <img src="/images/heroimage.svg" alt="" />
        </div>
      </div>
      <div className="bg-[#1B1E2F] ">
        <div className="max-w-7xl  w-full h-[150px] grid grid-cols-3  items-center mx-auto">
          {" "}
          <img src="/images/shake.svg" className="m-auto" width={150} alt="" />
          <img src="/images/rokcet.svg" className="m-auto" width={195} alt="" />
          <img src="/images/cash.svg" className="m-auto" width={175} alt="" />
        </div>
      </div>
    </>
  );
}

export default HeroPage;

export type DetailCardContent = {
  img: string;
  label: string;
  text: string;
};

const DetailCard = ({ content }: { content: DetailCardContent }) => {
  return (
    <div>
      <img className="pt-6" src={content.img} alt="" />

      <h4 className="py-2 text-xl">{content.label}</h4>
      <p className="font-stolzl">{content.text}</p>
    </div>
  );
};

const content: DetailCardContent[] = [
  {
    img: "/images/image1.png",
    label: "IT Companies",
    text: `Optimize IT operations and gain a competitive edge with CRP. Reduce DevOps costs, boost profits, and enhance customer service with AWS's efficient cloud solutions.`,
  },
  {
    img: "/images/image2.png",
    label: "Independent Software Vendors",
    text: `The CRP program aids ISVs in upgrading tech and market stance, cutting costs, boosting revenue through AWS, and enhancing customer service.`,
  },
  {
    img: "/images/image3.png",
    label: "Freelancers",
    text: `CRP empowers Freelancers to increase earnings via efficient AWS cloud solutions, enhancing services and revenue without extra workload.`,
  },
];

export const ProgramFor = () => {
  return (
    <div className="max-w-7xl space-y-6 mx-auto">
      <div className="space-y-4 mt-16">
        <h4 className="font-stolzl-r text-3xl text-center">
          who is this program for
        </h4>
        <p className="font-stolzl text-center">
          This program drives AWS global growth by encouraging early cloud
          adaptation.
        </p>
      </div>
      <div className="grid grid-cols-3 gap-6">
        {content.map((cont: DetailCardContent) => (
          <DetailCard content={cont} />
        ))}
      </div>
      <div className="text-center">
        <ButtonComp>{"Enroll as a Partner"}</ButtonComp>
      </div>
    </div>
  );
};

const CollabCard = ({ content }: any) => {
  return (
    <Card>
      <CardContent>
        <div className="h-[200px]  flex justify-center items-center">
          <img
            className="pt-6 flex  text-center items-center  "
            width={125}
            src={content.img}
            alt=""
          />
        </div>
        <CardHeader className="px-0 py-4 text-center">
          <CardTitle>{content.label}</CardTitle>
        </CardHeader>
        <CardDescription className=" font-stolzl text-center ">
          {content.text}
        </CardDescription>
      </CardContent>
    </Card>
  );
};

const collabData = [
  {
    img: "/images/puzz.svg",
    label: "Partner",
    text: "Partners enhance their business growth and market stance by leveraging AWS's cloud solutions and earning launch incentives from Cloudzenia partner.",
  },
  {
    img: "/images/cloudz_logo.svg",
    label: "Cloudzenia",
    text: "Cloudzenia rewards each partner-led AWS launch, fueling growth and merging innovative cloud solutions with the trending market demands.",
  },
  {
    img: "/images/aws.png",
    label: "Amazon Web Services",
    text: "By collaborating strategically, AWS broadens its influence and advances innovation, cementing its position as a pioneer in a cloud-driven business era.",
  },
];

export const Collab = () => {
  return (
    <div className="max-w-7xl mx-auto mt-20 space-y-12">
      <div className="space-y-4">
        <h4 className="text-3xl text-center">
          A win-win collaboration for everyone!
        </h4>
        <p className=" font-stolzl text-center">
          Let's empower growth and unlock potential, together.
        </p>
      </div>
      <div className="grid grid-cols-3 gap-8 pb-20">
        {collabData.map((cont: DetailCardContent) => (
          <CollabCard content={cont} />
        ))}
      </div>
    </div>
  );
};
