import Div from 'src/components/typography/div'
import { CardDescription } from 'src/components/ui/card'
import { useAuth } from 'src/lib/useAuth'
import { LoginWithOtp } from 'src/views/login/LoginWithOtp'

function Login() {
    const auth = useAuth()

    return (
        <Div className='  h-[100vh] p-0 relative overflow-hidden flex flex-col-reverse md:flex-row '>

            <div className=' min-w-[350px] px-6 h-full md:h-full items-center mx-auto text-center flex flex-col justify-center'>
                <img src='/images/cloudz_logo.svg' className='z-10 w-40' alt='CloudZenia' />

                <h1 className=' font-bold  text-3xl text-center '>Welcome Partners</h1>
                <CardDescription className='font-stolzl py-6 pt-2'>Please enter you details to sign in</CardDescription>
                
                <LoginWithOtp auth={auth}/>  
            </div>
            <div className='bg-black md:flex hidden  relative md:w-1/2 h-1/3 md:h-full items-center 
            flex-col justify-center '>
                <img src='/images/layer.png' className='z-10 w-[40w]' alt='CloudZenia' />
                <div className=' w-full absolute h-[100vh] bg-gradient-to-b from-[#0430BD] to-[#071768] ' ></div>

            </div>
        </Div>
    )
}

export default Login

