import { ReactNode, createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import {
  IUser,
  LOCAL_STORAGE_KEYS,
  USERTYPE,
  createUserDto,
} from "src/components/Types";
import { useClaimStore } from "src/store/claimStore";
import { useProjectStore } from "src/store/projectStore";
import { removeLocalData } from "./Api";
import { routes } from "./routes";

export interface LogOutType {
  emailId: string;
  logoutStatus: boolean;
}

export interface AuthValuesType {
  loading: boolean;
  setLoading: (T: boolean) => void;
  user: IUser | null;
  setUser: (T: IUser | null) => void;
  login: (T: unknown) => void;
  logout: () => void;
  register: (T: createUserDto) => void;
  sendOtp: (T: any) => void;
  verifyOtp: (T: any) => Promise<void>;
}

const defaultProvider: AuthValuesType = {
  user: null,
  loading: true,
  setLoading: (T: boolean) => {},
  setUser: (T: IUser | null) => {},
  login: (T: any) => {},
  logout: () => {},
  register: (T: createUserDto) => {},
  sendOtp: (opt: any) => {},
  //@ts-ignore
  verifyOtp: (T: any) => Promise<any>,
};

const AuthContext = createContext(defaultProvider);

const AuthProvider = ({ children }: { children?: ReactNode }) => {
  const resetProjectStore = useProjectStore().resetAll;
  const resetClaimStore = useClaimStore().resetAll;
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState<IUser | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      const token = window.localStorage.getItem(
        LOCAL_STORAGE_KEYS.CLOUDZENIA_APN_PARTNERSHIP_TOKEN
      );

      const json = window.localStorage.getItem(
        LOCAL_STORAGE_KEYS.CLOUDZENIA_APN_PARTNERSHIP
      );

      if (!token || !json) return;
      const userData = JSON.parse(json);
      setUser(userData);

      if (userData.userType === USERTYPE.USER) navigate("/dashboard/projects");
      if (userData.userType === USERTYPE.ADMIN) navigate("/dashboard/onboarding");
      if (userData.userType === USERTYPE.SUPER_ADMIN)
        navigate("/dashboard/admin/projects");
    }
  }, []);

  async function login(data: any) {
    if (data.emailId === "" || data.password === "")
      return toast.warning("Enter credentials properly!!");

    await routes.APIS.AUTHENTICATION.LOG_IN(data)
      .then((res: any) => {
        window.localStorage.setItem(
          LOCAL_STORAGE_KEYS.CLOUDZENIA_APN_PARTNERSHIP_TOKEN,
          JSON.stringify({ token: res.token })
        );
        delete res.token;
        window.localStorage.setItem(
          LOCAL_STORAGE_KEYS.CLOUDZENIA_APN_PARTNERSHIP,
          JSON.stringify(res)
        );
        setUser(res);
        navigate("/dashboard/admin/onboarding");
      })
      .catch((err: any) => {
        console.log("here", err);
      });
  }

  async function logout() {
    window.localStorage.removeItem(
      LOCAL_STORAGE_KEYS.CLOUDZENIA_APN_PARTNERSHIP
    );
    window.localStorage.removeItem(
      LOCAL_STORAGE_KEYS.CLOUDZENIA_APN_PARTNERSHIP_TOKEN
    );
    toast.success("Logout Succesfull");
    resetClaimStore();
    resetProjectStore();
    setUser(null);
    navigate("/login");
  }

  async function sendOtp(data: any) {
    await routes.APIS.AUTHENTICATION.SEND_OTP(data).then((res) => {
      if (res.status === true) {
        toast("OTP Sent, Please Check Your Email!!");
        return res;
      } else {
        toast("Error Occured in sending OTP");
        return { status: false };
      }
    });
  }

  async function verifyOtp(data: any) {
    await routes.APIS.AUTHENTICATION.VERIFY_OTP({
      emailId: data.emailId,
      otp: parseInt(data.otp),
    })
      .then((res) => {
        if (res.status === true) {
          const token = { token: res.token };
          var obj = {
            status: res.status,
            addressLine1: res?.userDetails?.addressLine1,
            addressLine2: res?.userDetails?.addressLine2,
            country: res?.userDetails?.country,
            emailId: res?.userDetails?.emailId,
            firstName: res?.userDetails?.firstName,
            lastName: res?.userDetails?.lastName,
            mobileNumber: res?.userDetails?.mobileNumber,
            pinCode: res?.userDetails?.pinCode,
            password: res?.userDetails?.password,
            roles: res.userDetails.roles,
            state: res?.userDetails?.state,
            userCategory: res?.userDetails?.userCategory,
            userId: res?.userDetails?.userId,
            userType: res.userDetails?.userType,
            userInvited: res.userDetails?.userInvited,
          };
          if (
            window.localStorage.getItem(
              LOCAL_STORAGE_KEYS.CLOUDZENIA_APN_PARTNERSHIP_TOKEN
            )
          ) {
            window.localStorage.removeItem(
              LOCAL_STORAGE_KEYS.CLOUDZENIA_APN_PARTNERSHIP_TOKEN
            );
          }
          if (
            window.localStorage.getItem(
              LOCAL_STORAGE_KEYS.CLOUDZENIA_APN_PARTNERSHIP
            )
          ) {
            window.localStorage.removeItem(
              LOCAL_STORAGE_KEYS.CLOUDZENIA_APN_PARTNERSHIP
            );
          }
          window.localStorage.setItem(
            LOCAL_STORAGE_KEYS.CLOUDZENIA_APN_PARTNERSHIP,
            JSON.stringify(obj)
          );
          window.localStorage.setItem(
            LOCAL_STORAGE_KEYS.CLOUDZENIA_APN_PARTNERSHIP_TOKEN,
            JSON.stringify(token)
          );
          var url ="/dashboard/waiting";

          if (obj.userType === USERTYPE.SUPER_ADMIN) {
            console.log(1);
            toast.error("Login Unauthorized!!");
            removeLocalData();
            return;
          } else if (obj.userType === USERTYPE.USER) {
            url ="/dashboard/projects"
            console.log(3);
            toast.success("Login Successfull!");
          } else if (!obj.userInvited) {
            console.log(2);
            toast.success("Login Successfull!");
            url = "/dashboard/waiting"
          } else {
            obj.userType = USERTYPE.USER;
            console.log(12, obj);
            url = "/dashboard/projects"
            toast.success("Login Successfull!");
          }
          
          setUser(obj)
          navigate(url)
        } else {
          toast.warning("Wrong Otp Provided!!");
          setUser(null);
          window.localStorage.removeItem(
            LOCAL_STORAGE_KEYS.CLOUDZENIA_APN_PARTNERSHIP
          );
          window.localStorage.removeItem(
            LOCAL_STORAGE_KEYS.CLOUDZENIA_APN_PARTNERSHIP
          );
        }
      })
      .catch((err) => {
        toast.error("OTP provided could not be verified, Please try again!!!");
      });
  }

  async function register(userDetails: createUserDto) {
    try {
      const res = await routes.APIS.AUTHENTICATION.SIGN_UP(userDetails).then(
        (res) => {
          toast.success("Registeration Succesfull!! for user", res.firstName);
          navigate("/login");
        }
      );
    } catch (error) {
      toast.error("Registeration Failed!!");
      console.log(error);
    }
  }
  const values: AuthValuesType = {
    user,
    setUser,
    register,
    login,
    logout,
    sendOtp,
    verifyOtp,
    setLoading,
    loading,
  };

  return (
    <AuthContext.Provider value={values}>{children} </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
