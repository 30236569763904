import { HamburgerMenuIcon } from "@radix-ui/react-icons";
import { BadgePercent, ChevronLeft, Mail, PanelsTopLeft, UserIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Icons } from "src/components/custom/icons";
import { USERTYPE } from "src/components/Types";
import Div from "src/components/typography/div";
import { Button } from "src/components/ui/button";
import useDevice from "src/lib/hooks/useDevice";
import { TabItems } from "./header";

function Sidebar({user}:any) {
  const { pathname } = useLocation();
  const device = useDevice();
  const [expanded, setExpanded] = useState(true);

  const isAdmin = user?.userType === USERTYPE.ADMIN
  const isSuperAmdin = user.userType === USERTYPE.SUPER_ADMIN
  const isInvited = user?.userInvited;

  let tabs

  if (isAdmin) tabs = [...adminTabs , ...userTabs ]
  else if(isSuperAmdin)  tabs = superAdminTabs 
  else if (!isAdmin && !isInvited) tabs = newUserTab;
  else tabs = userTabs;  
  
  useEffect(() => {
    if (device === "mobile") {
      setExpanded(false);
    }
  }, [device]);

  return (
    <Div
      className={`bg-inherit space-y-4 ${
        expanded ? "w-[290px]" : "w-20"
      }  h-screen transition-all sticky top-0 duration-500 `}
    >
      <div className="w-full  text-right">
        <Button
          variant="ghost"
          className="hover:bg-gray-800"
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? (
            <ChevronLeft className="text-white" />
          ) : (
            <HamburgerMenuIcon className="text-white w-full h-full" />
          )}
        </Button>
      </div>

      <img
        className=" p-2 w-full max-w-[120px]  transition-all duration-300  mx-auto "
        src="/images/cloudz_logo.svg"
        alt="logo"
      />

      <TabItems pathname={pathname} tabs={tabs} expanded={expanded} />
    </Div>
  );
}

export default Sidebar;


const superAdminTabs = [
  {
    title: "OnBoarding",
    href: "/dashboard/admin/onboarding",
    icon: <Mail className="h-full w-full " />,
  },
  {
    title: "All Projects",
    href: "/dashboard/admin/projects",
    icon: <PanelsTopLeft className="h-full w-full " />,
  },
  {
    title: "Claims",
    href: "/dashboard/admin/claims",
    icon: <BadgePercent className="h-full w-full " />,
  },
];

const adminTabs = [
  {
    title: "OnBoarding",
    href: "/dashboard/onboarding",
    icon: <Mail className="h-full w-full " />,
  }
]

const userTabs = [
  {
    title: "My Projects",
    href: "/dashboard/projects",
    icon: <Icons.ProjectsWhite className="h-full w-full " />,
  },
  {
    title: "Cash Unlocked",
    href: "/dashboard/cash-unlocked",
    icon: <Icons.Cash className="h-full w-full" />,
  },
  {
    title: "Reward Claim",
    href: "/dashboard/rewards-claim",
    icon: <Icons.Rewards className="h-full w-full" />,
  },
  {
    title: "Support",
    href: "/dashboard/support",
    icon: <Icons.Support className="h-full w-full" />,
  },
];

const newUserTab = [
  {
    title: "My Profile",
    href: "/dashboard/view-profile",
    icon: <UserIcon />,
  },
];
