import { HamburgerMenuIcon } from "@radix-ui/react-icons";
import {
  BadgePercent,
  ChevronRight,
  Mail,
  PanelsTopLeft,
  User,
  UserIcon,
} from "lucide-react";
import { ReactNode } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IUser, USERTYPE } from "src/components/Types";
import CustomDropdown from "src/components/custom/dropdown";
import { Icons } from "src/components/custom/icons";
import Div from "src/components/typography/div";
import Lead from "src/components/typography/lead";
import Ul from "src/components/typography/ul";
import { Avatar, AvatarFallback, AvatarImage } from "src/components/ui/avatar";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "src/components/ui/dropdown-menu";
import { Separator } from "src/components/ui/separator";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetTrigger,
} from "src/components/ui/sheet";
import { useAuth } from "src/lib/useAuth";
import { useProjectStore } from "src/store/projectStore";

export interface Tab {
  title: string;
  href: string;
  icon: ReactNode;
  hide?: boolean;
  access?: "admin" | "individual";
}

export const getUserName = (user: IUser) => {
  if (user?.firstName) return user.firstName;
  else return "Partner";
};
export function AvatarDropDown({user}: any) {
  const navigate = useNavigate();
  const { reset } = useProjectStore();
  const auth = useAuth();

  function handleLogout() {
    reset();
    auth.logout();
  }

  let userTag: string;
  userTag = user?.emailId ? user.emailId[0] : "U";

  return (
    <DropdownMenu>
      <div className="flex gap-2">
        <DropdownMenuTrigger className=" right-10 top-6">
          <CustomDropdown>
            <Avatar className="border h-8 w-8">
              <AvatarImage src="#" />
              <AvatarFallback className="">{userTag}</AvatarFallback>
            </Avatar>
            <p className=" flex px-2 text-sm w-max m-auto">
              {getUserName(user)} 
            </p>
          </CustomDropdown>
        </DropdownMenuTrigger>
      </div>

      <DropdownMenuContent className="">
        <DropdownMenuGroup>
          <DropdownMenuItem onClick={() => navigate("/dashboard/view-profile")}>
            Profile
          </DropdownMenuItem>
          <Separator />
          <DropdownMenuItem onClick={handleLogout}>Sign Out</DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export function MobileHeader({ user }: any) {
  const navigate = useNavigate();

  const isAdmin = user?.userType === USERTYPE.SUPER_ADMIN;
  const isInvited = user?.userInvited;

  let sidebarTabs: any;
  const adminTabs = [
    {
      title: "OnBoarding",
      href: "/dashboard/admin/onboarding",
      icon: <Mail className="h-full w-full " />,
    },
    {
      title: "All Projects",
      href: "/dashboard/admin/projects",
      icon: <PanelsTopLeft className="h-full w-full max-w-6" />,
    },
    {
      title: "Claims",
      href: "/dashboard/admin/claims",
      icon: <BadgePercent className="h-full w-full " />,
    },
  ];

  const userTabs = [
    {
      title: "My Projects",
      href: "/dashboard/my-projects",
      icon: <Icons.ProjectsWhite className="h-6 w-6" />,
    },
    {
      title: "Cash Unlocked",
      href: "/dashboard/cash-unlocked",
      icon: <Icons.Cash className="h-6 w-6" />,
    },
    {
      title: "Reward Claim",
      href: "/dashboard/rewards-claim",
      icon: <Icons.Rewards className="h-6 w-6" />,
    },
    {
      title: "Support",
      href: "/dashboard/support",
      icon: <Icons.Support className="h-6 w-6" />,
    },
  ];

  const newUserTab = [
    {
      title: "My Profile",
      href: "/dashboard/view-profile",
      icon: <UserIcon />,
    },
  ];

  if (isAdmin) sidebarTabs = adminTabs;
  else if (!isAdmin && !isInvited) sidebarTabs = newUserTab;
  else sidebarTabs = userTabs;

  return (
    <div className="bg-black sticky top-0 z-40 flex items-center  justify-between pl-3 pr-3 ">
      <div className="w-16 items-center  h-16">
        <img
          className="  w-full py-2  items-center h-full"
          src="/images/cloudz_logo.svg"
          alt="main"
        />
      </div>

      <Sheet>
        <SheetTrigger asChild>
          <HamburgerMenuIcon className="text-white w-8 h-8 mr-2" />
        </SheetTrigger>
        <SheetContent
          side={"left"}
          className="text-white overflow-hidden w-full flex flex-col  bg-black border-t-2 border-gray-500 mt-16  px-6 border-none"
        >
          <SheetClose className=" w-full ">
            <span className="flex  text-white/50 justify-between items-center">
              <div
                className="flex items-center w-full gap-4 text-md"
                onClick={() => navigate("/dashboard/view-profile")}
              >
                <Avatar className="w-16 h-16">
                  <AvatarImage>{user?.profileImage}</AvatarImage>
                  <AvatarFallback className="bg-black">
                    <User className="" />
                  </AvatarFallback>
                </Avatar>
                <p> {user?.firstName}</p>
              </div>
              <ChevronRight className="w-6 h-6 text-white/50" />
            </span>
          </SheetClose>

          <ul className="space-y-6 pl-4">
            {sidebarTabs.map((item: any) => (
              <SheetClose className=" w-full ">
                <span className="flex  text-white/50 justify-between items-center">
                  <div
                    className=" flex gap-4 text-md"
                    onClick={() => navigate(item.href)}
                  >
                    {item.icon}
                    {item.title}
                  </div>
                  <ChevronRight className="w-6 h-6 text-white/50" />
                </span>
              </SheetClose>
            ))}
          </ul>
        </SheetContent>
      </Sheet>
    </div>
  );
}

export function TabItems({ pathname, expanded, tabs }: any) {

  return (
    <Ul className="flex flex-col m space-y-3 m-0">
      {tabs.map((tab: Tab, index: number) => (
        <Link key={index} to={tab.href}>
          <Div
            className={`${
              pathname === tab.href
                ? "bg-gray-700/50 rounded-lg"
                : "bg-transparent"
            } animate-out slide-in-from-top-9 p-2 duration-300 text-left max-w-[260px]`}
          >
            <Lead
              className={`my-auto font-light text-[14px] ${
                pathname === tab.href ? "text-white" : ""
              }`}
            >
              <div
                className={`flex transition-all duration-300 ${
                  expanded ? "gap-4" : "justify-center"
                }`}
              >
                <div className="w-[30px] h-[30px] flex justify-center items-center">
                  {tab.icon}
                </div>
                <h2
                  className={`transition-all duration-500 my-auto text-lg line-clamp-1 ${
                    expanded ? "w-full" : "w-0"
                  }`}
                >
                  {tab.title}
                </h2>
              </div>
            </Lead>
          </Div>
        </Link>
      ))}
    </Ul>
  );
}
